import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormProcessor from '@ps/react-forms/processor/FormProcessor';
import customStaticPageStyle from 'ps-assets/jss/customStaticPageStyle.jsx';
import Layout from 'ps-components/Layout/Layout.jsx';
import Button from 'components/CustomButtons/Button.jsx';
// import formJson from 'forms/covid-score/covid-score.json';
// import formPayload from 'forms/covid-score/payload.json';
// import LinearProgress from '@material-ui/core/LinearProgress';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem';
// import Tooltip from '@material-ui/core/Tooltip';
import { withPrefix } from 'gatsby';
import loadingGIF from 'ps-assets/img/star2.gif';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import RefreshIcon from '@material-ui/icons/Refresh';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomSnackbar from 'components/Snackbar/Snackbar';
import { browserWindow } from 'util/localStorageHelper';
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
class CovidScore extends React.Component {
  state = {
    category: null,
    mobileNo: ``,
  };

  onSubmit = async (formData) => {
    browserWindow.scrollTo(0, 0);
    this.setState({
      loading: true,
    });
    let payload = formData.formPayload;
    let keys = Object.keys(payload);
    for (let i = 0; i < keys.length; i++) {
      let element = payload[keys[i]];
      payload[keys[i]] = element.split(`, `).join(`,`);
    }
    var response = await fetch(
      `${process.env.CUSTOMER_PORTAL_API_URL}/api/services/authentication/calcualte/score`,
      {
        method: `POST`,
        headers: {
          'Content-Type': `application/json`,
        },
        body: JSON.stringify(payload),
      }
    );
    var resJson = await response.json();
    if (resJson.message) {
      let score = 25;
      if (resJson.message === `MODERATE`) {
        score = 50;
      }
      if (resJson.message === `HIGH`) {
        score = 100;
      }
      this.setState({
        category: score,
        message: resJson.message,
        loading: false,
        riskId: resJson.riskId,
      });
    }
  };

  handleSubmit = async () => {
    let response = await fetch(
      `${process.env.CUSTOMER_PORTAL_API_URL}/api/services/authentication/update/risk?mobileNo=${this.state.mobileNo}&requestId=${this.state.riskId}`,
      {
        method: `POST`,
        headers: {
          'Content-Type': `application/json`,
        },
      }
    );
    response = await response.json();
    if (response.status === `success`) {
      this.setState({
        leadResponse: `success`,
        mobileNo: ``,
      });
    } else {
      this.setState({
        leadResponse: `failure`,
        mobileNo: ``,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      mobileNo: e.target.value,
    });
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    let formJson = await fetch(
      `${process.env.CUSTOMER_PORTAL_API_URL}/api/services/authentication/fetch/forms?formId=covid&formType=formData`
    );
    formJson = await formJson.json();
    let formPayload = await fetch(
      `${process.env.CUSTOMER_PORTAL_API_URL}/api/services/authentication/fetch/forms?formId=covid&formType=payloadData`
    );
    formPayload = await formPayload.json();
    this.setState({
      formJson: formJson,
      formPayload: formPayload,
      loading: false,
    });
  }

  closeSnackBar = () => {
    this.setState({
      leadResponse: ``,
    });
  };

  render() {
    const { classes } = this.props;
    // let classLinearBar;
    // if (this.state.category === 25) classLinearBar = classes.low;
    // else if (this.state.category === 50) classLinearBar = classes.moderate;
    // else if (this.state.category === 100) classLinearBar = classes.high;
    console.log(this.state.category);
    var isMobile = browserWindow.navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );

    return (
      <Layout small={true} title={``} summary={``}>
        <Helmet key="helmetTags">
        <title>Covid-19 | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href={url + `covid-19`}
          />
          <meta name="title" content="Covid-19 | StarHealth.in" />
          <meta name="twitter:title" content="Covid-19 | StarHealth.in" />
          <meta
            name="description"
            content="Star Health Insurance coronavirus disease Risk-assessment"
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
          />
          <meta property="og:title" content="Covid-19 | StarHealth.in" />
          <meta property="og:description" content="Star Health Insurance coronavirus disease Risk-assessment" />
          <meta property="og:url" content={url + `covid-19`}/>
          <meta name="Covid-19 | StarHealth.in" />
          <meta name="twitter:description" content="Star Health Insurance coronavirus disease Risk-assessment" />
          <meta property="twitter:url" content={url + `covid-19`} />
        </Helmet>
      )}
        <GridContainer>
          <GridItem
            md={7}
            sm={12}
            xs={12}
            style={{ marginLeft: `auto`, marginRight: `auto` }}>
            <div
              className={classNames(classes.main, classes.mainRaised)}
              style={{ marginBottom: 100 }}>
              <div className={classes.section}>
                <div
                  className={classes.container}
                  style={{ padding: `30px`, marginTop: `200px` }}>
                     <h1  className={classes.h1title} style={{textAlign:'center'}}>
                    Covid-19
                    </h1>
                  The Star Health and Allied Insurance Co Ltd coronavirus
                  disease Risk-assessment tool has been developed for our Star
                  Patrons. This interaction should not be considered as a
                  substitute for medical advice. Any information you share with
                  us will be kept strictly confidential.
                  <br />
                  <br />
                  <GridContainer>
                    <GridItem
                      md={12}
                      xs={12}
                      style={{ marginLeft: `auto`, marginRight: `auto` }}>
                      {!this.state.category &&
                        !this.state.loading &&
                        this.state.formJson && (
                        <FormProcessor
                          key={`form`}
                          formJson={JSON.parse(
                            JSON.stringify(this.state.formJson)
                          )}
                          formData={{}}
                          action={`add`}
                          rendererType={`material`}
                          renderStyle={`chat_bot`}
                          formPayload={this.state.formPayload}
                          onSubmit={this.onSubmit}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                  {this.state.leadResponse === `success` && (
                    <CustomSnackbar
                      open={true}
                      status={`success`}
                      message={`Thank You for leaving your number with us! We will be in touch shortly!`}
                      flag={this.closeSnackBar}
                    />
                  )}
                  {this.state.leadResponse === `failure` && (
                    <CustomSnackbar
                      open={true}
                      status={`sucess`}
                      message={`Something went wrong. Please try again later`}
                      flag={this.closeSnackBar}
                    />
                  )}
                  {this.state.category === 25 && !this.state.loading && (
                    <div>
                      <GridContainer>
                        <GridItem
                          md={12}
                          xs={12}
                          style={{ textAlign: `center` }}>
                          <Card>
                            <CardBody>
                              <GridContainer>
                                <GridItem md={12} xs={12}>
                                  <img
                                    // width='100'
                                    className={classes.img}
                                    src={withPrefix(`/covid/low.png`)}
                                  />
                                </GridItem>
                                <GridItem
                                  md={12}
                                  xs={12}
                                  style={{
                                    textAlign: `right`,
                                    marginTop: `10px`,
                                  }}>
                                  <Button
                                    simple
                                    color='primary'
                                    onClick={() =>
                                      this.setState({
                                        category: null,
                                        message: null,
                                      })
                                    }
                                    style={{ padding: `0` }}>
                                    <RefreshIcon /> Retake Your Test
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <br />
                        <GridItem md={12} xs={12}>
                          <Card>
                            <CardBody>
                              <GridContainer>
                                <GridItem md={6} xs={12}>
                                  <img
                                    // width='100'
                                    src={withPrefix(`/covid/bg.png`)}
                                    style={{
                                      width: `100%`,
                                    }}
                                  />
                                </GridItem>
                                <GridItem md={6} xs={12}>
                                  <h5
                                    style={{
                                      paddingLeft: `20px`,
                                      fontFamily: `Roboto Slab`,
                                    }}>
                                    Based on your inputs, Talk to Star advises
                                    the following:
                                  </h5>
                                  <ol>
                                    <li>
                                      Stay At home and practice Social
                                      Distancing
                                    </li>
                                    <li>
                                      Prevention and precautions – Contact Talk
                                      to Star and speak to our Experts -{` `}
                                      <Button
                                        simple
                                        color='primary'
                                        href={
                                          isMobile
                                            ? `tel:918657589398`
                                            : `/talk-to-doctor`
                                        }
                                        style={{
                                          border: `solid 1px`,
                                          padding: `8px 16px`,
                                          borderRadius: `20px`,
                                        }}>
                                        SPEAK TO A DOCTOR
                                      </Button>
                                    </li>
                                    <li>
                                      COVID 19 testing may be required at your
                                      physician’s advise
                                    </li>
                                    <li>
                                      Monitor your symptoms and get medical
                                      attention if your situation worsens
                                    </li>
                                  </ol>
                                  <div>
                                    Do you want one of our experts to call you
                                    back? Please leave your phone number below:
                                  </div>
                                  <CustomInput
                                    id='name'
                                    labelText='Your Mobile Number'
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.formControl,
                                    }}
                                    value={this.state.mobileNo}
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <Button
                                    color='primary'
                                    round
                                    disabled={
                                      this.state.mobileNo &&
                                      this.state.mobileNo.length >= 10
                                    }
                                    onClick={this.handleSubmit}>
                                    Submit
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                  {this.state.category === 50 && !this.state.loading && (
                    <div>
                      <GridContainer>
                        <GridItem
                          md={12}
                          xs={12}
                          style={{ textAlign: `center` }}>
                          <Card>
                            <CardBody>
                              <GridContainer>
                                <GridItem md={12} xs={12}>
                                  <img
                                    // width='100'
                                    className={classes.img}
                                    src={withPrefix(`/covid/mid.png`)}
                                  />
                                </GridItem>
                                <GridItem
                                  md={12}
                                  xs={12}
                                  style={{
                                    textAlign: `right`,
                                    marginTop: `10px`,
                                  }}>
                                  <Button
                                    simple
                                    color='primary'
                                    onClick={() =>
                                      this.setState({
                                        category: null,
                                        message: null,
                                      })
                                    }
                                    style={{ padding: `0` }}>
                                    <RefreshIcon /> Retake Your Test
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <br />
                        <GridItem md={12} xs={12}>
                          <Card>
                            <CardBody>
                              <GridContainer>
                                <GridItem md={6} xs={12}>
                                  <img
                                    src={withPrefix(`/covid/bg.png`)}
                                    style={{
                                      width: `100%`,
                                    }}
                                  />
                                </GridItem>
                                <GridItem md={6} xs={12}>
                                  <h5
                                    style={{
                                      paddingLeft: `20px`,
                                      fontFamily: `Roboto Slab`,
                                    }}>
                                    Based on your inputs, Talk to Star advises
                                    the following:
                                  </h5>
                                  <ol>
                                    <li>
                                      Prevention and precautions – Contact Talk
                                      to Star and speak to our Experts -{` `}
                                      <Button
                                        simple
                                        color='primary'
                                        href={
                                          isMobile
                                            ? `tel:918657589398`
                                            : `/talk-to-doctor`
                                        }
                                        style={{
                                          border: `solid 1px`,
                                          padding: `8px 16px`,
                                          borderRadius: `20px`,
                                        }}>
                                        SPEAK TO A DOCTOR
                                      </Button>
                                    </li>
                                    <li>
                                      Monitor your symptoms and get medical
                                      attention if your situation worsens.
                                    </li>
                                    <li>
                                      COVID 19 testing may be required at your
                                      physician’s advise
                                    </li>
                                    <li>
                                      Call your State helpline &nbsp; - &nbsp;
                                      <a href='https://www.mohfw.gov.in/pdf/coronvavirushelplinenumber.pdf'>
                                        CALL NOW
                                      </a>
                                    </li>
                                  </ol>
                                  <div>
                                    Do you want one of our experts to call you
                                    back? Please leave your phone number below:
                                  </div>
                                  <CustomInput
                                    id='name'
                                    labelText='Your Mobile Number'
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.formControl,
                                    }}
                                    value={this.state.mobileNo}
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <Button
                                    color='primary'
                                    round
                                    disabled={
                                      this.state.mobileNo &&
                                      this.state.mobileNo.length >= 10
                                    }
                                    onClick={this.handleSubmit}>
                                    Submit
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                  {this.state.category === 100 && !this.state.loading && (
                    <div>
                      <GridContainer>
                        <GridItem
                          md={12}
                          xs={12}
                          style={{ textAlign: `center` }}>
                          <Card>
                            <CardBody>
                              <GridContainer>
                                <GridItem md={12} xs={12}>
                                  <img
                                    // width='100'
                                    className={classes.img}
                                    src={withPrefix(`/covid/high.png`)}
                                  />
                                </GridItem>
                                <GridItem
                                  md={12}
                                  xs={12}
                                  style={{
                                    textAlign: `right`,
                                    marginTop: `10px`,
                                  }}>
                                  <Button
                                    simple
                                    color='primary'
                                    onClick={() =>
                                      this.setState({
                                        category: null,
                                        message: null,
                                      })
                                    }
                                    style={{ padding: `0` }}>
                                    <RefreshIcon /> Retake Your Test
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <br />
                        <GridItem md={12} xs={12}>
                          <Card>
                            <CardBody>
                              <GridContainer>
                                <GridItem md={6} xs={12}>
                                  <img
                                    src={withPrefix(`/covid/bg.png`)}
                                    style={{
                                      width: `100%`,
                                    }}
                                  />
                                </GridItem>
                                <GridItem md={6} xs={12}>
                                  <h5
                                    style={{
                                      paddingLeft: `20px`,
                                      fontFamily: `Roboto Slab`,
                                    }}>
                                    Based on your inputs, <b>Talk to Star</b>{` `}
                                    advises the following:{` `}
                                  </h5>
                                  <ol>
                                    <li>
                                      Prevention and precautions – Contact Talk
                                      to Star and speak to our Experts -{` `}
                                      <Button
                                        simple
                                        color='primary'
                                        href={
                                          isMobile
                                            ? `tel:918657589398`
                                            : `/talk-to-doctor`
                                        }
                                        style={{
                                          border: `solid 1px`,
                                          padding: `8px 16px`,
                                          borderRadius: `20px`,
                                        }}>
                                        SPEAK TO A DOCTOR
                                      </Button>
                                    </li>
                                    <li>
                                      Monitor your symptoms and get medical
                                      attention if your situation worsens.
                                    </li>
                                    <li>
                                      COVID 19 testing may be required at your
                                      physician’s advise
                                    </li>
                                    <li>
                                      Call your State helpline &nbsp; - &nbsp;
                                      <a href='https://www.mohfw.gov.in/pdf/coronvavirushelplinenumber.pdf'>
                                        CALL NOW
                                      </a>
                                    </li>
                                  </ol>
                                  <div>
                                    Do you want one of our experts to call you
                                    back? Please leave your phone number below:
                                  </div>
                                  <CustomInput
                                    id='name'
                                    labelText='Your Mobile Number'
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.formControl,
                                    }}
                                    value={this.state.mobileNo}
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <Button
                                    color='primary'
                                    round
                                    disabled={
                                      !this.state.mobileNo ||
                                      this.state.mobileNo.length < 10
                                    }
                                    onClick={this.handleSubmit}>
                                    Submit
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                  {this.state.category && !this.state.loading && (
                    <GridContainer>
                      <GridItem md={12} xs={12}>
                        <Card>
                          <CardBody>
                            <GridContainer>
                              <GridItem md={12} xs={12}>
                                <h4>
                                  <b>
                                    Prevention and Precaution – WHO
                                    recommendations
                                  </b>
                                </h4>
                                <br />
                                <h5>
                                  Basic protective measures against the new
                                  coronavirus
                                </h5>
                                <p>
                                  Stay aware of the latest information on the
                                  COVID-19 outbreak, available on the WHO
                                  website and through your national and local
                                  public health authority. Most people who
                                  become infected experience mild illness and
                                  recover, but it can be more severe for others.
                                  Take care of your health and protect others by
                                  doing the following:
                                </p>
                                <h5>
                                  <b>Wash your hands frequently</b>
                                </h5>
                                <p>
                                  Regularly and thoroughly clean your hands with
                                  an alcohol-based hand rub or wash them with
                                  soap and water.
                                </p>
                                <p>
                                  Why? Washing your hands with soap and water or
                                  using alcohol-based hand rub kills viruses
                                  that may be on your hands.
                                </p>
                                <h5>
                                  <b>Maintain social distancing</b>
                                </h5>
                                <p>
                                  Maintain at least 1 metre (3 feet) distance
                                  between yourself and anyone who is coughing or
                                  sneezing.
                                </p>
                                <p>
                                  Why? When someone coughs or sneezes they spray
                                  small liquid droplets from their nose or mouth
                                  which may contain virus. If you are too close,
                                  you can breathe in the droplets, including the
                                  COVID-19 virus if the person coughing has the
                                  disease.
                                </p>
                                <h5>
                                  <b>Avoid touching eyes, nose and mouth</b>
                                </h5>
                                <p>
                                  Why? Hands touch many surfaces and can pick up
                                  viruses. Once contaminated, hands can transfer
                                  the virus to your eyes, nose or mouth. From
                                  there, the virus can enter your body and can
                                  make you sick.
                                </p>
                                <h5>
                                  <b>Practice respiratory hygiene</b>
                                </h5>
                                <p>
                                  Make sure you, and the people around you,
                                  follow good respiratory hygiene. This means
                                  covering your mouth and nose with your bent
                                  elbow or tissue when you cough or sneeze. Then
                                  dispose of the used tissue immediately.
                                </p>
                                <p>
                                  Why? Droplets spread virus. By following good
                                  respiratory hygiene you protect the people
                                  around you from viruses such as cold, flu and
                                  COVID-19.
                                </p>
                                <h5>
                                  <b>
                                    If you have fever, cough and difficulty
                                    breathing, seek medical care early
                                  </b>
                                </h5>
                                <p>
                                  Stay home if you feel unwell. If you have a
                                  fever, cough and difficulty breathing, seek
                                  medical attention and call in advance. Follow
                                  the directions of your local health authority.
                                </p>
                                <p>
                                  Why? National and local authorities will have
                                  the most up to date information on the
                                  situation in your area. Calling in advance
                                  will allow your health care provider to
                                  quickly direct you to the right health
                                  facility. This will also protect you and help
                                  prevent spread of viruses and other
                                  infections.
                                </p>
                                <h5>
                                  <b>
                                    Stay informed and follow advice given by
                                    your healthcare provider
                                  </b>
                                </h5>
                                <p>
                                  Stay informed on the latest developments about
                                  COVID-19. Follow advice given by your
                                  healthcare provider, your national and local
                                  public health authority or your employer on
                                  how to protect yourself and others from
                                  COVID-19.
                                </p>
                                <p>
                                  Why? National and local authorities will have
                                  the most up to date information on whether
                                  COVID-19 is spreading in your area. They are
                                  best placed to advise on what people in your
                                  area should be doing to protect themselves.
                                </p>
                                <h3>
                                  Protection measures for persons who are in or
                                  have recently visited (past 14 days) areas
                                  where COVID-19 is spreading
                                </h3>
                                <p>Follow the guidance outlined above.</p>
                                <ul>
                                  <li>
                                    Stay at home if you begin to feel unwell,
                                    even with mild symptoms such as headache and
                                    slight runny nose, until you recover. Why?
                                    Avoiding contact with others and visits to
                                    medical facilities will allow these
                                    facilities to operate more effectively and
                                    help protect you and others from possible
                                    COVID-19 and other viruses.
                                  </li>
                                  <li>
                                    If you develop fever, cough and difficulty
                                    breathing, seek medical advice promptly as
                                    this may be due to a respiratory infection
                                    or other serious condition. Call in advance
                                    and tell your provider of any recent travel
                                    or contact with travelers. Why? Calling in
                                    advance will allow your health care provider
                                    to quickly direct you to the right health
                                    facility. This will also help to prevent
                                    possible spread of COVID-19 and other
                                    viruses.
                                  </li>
                                </ul>
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  )}
                  {this.state.loading && (
                    <GridContainer>
                      <div
                        style={{ margin: `0 auto` }}
                        className={classes.loadingGIF}>
                        <img src={loadingGIF} />
                      </div>
                    </GridContainer>
                  )}
                </div>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </Layout>
    );
  }
}
CovidScore.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(customStaticPageStyle)(CovidScore);
